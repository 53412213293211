function load() {
  (function () {
    let node = document.createElement("script");
    node.src = "//l.getsitecontrol.com/lw1mq09w.js";
    node.type = "text/javascript";
    node.async = true;
    node.id = "getsitecontrol";
    document.getElementsByTagName("body")[0].appendChild(node);
    // console.log("Getsitecontrol actived!!");
  })();
}

function call() {
  return `${load.name}()`;
}

export { load, call };
