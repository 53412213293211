import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Rx';
import { AuthService } from './auth.service';
import { Cart } from '../models/cart.model';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class PaymentService {

  private totalCartSubject = new ReplaySubject<number>();
  totalCart$ = this.totalCartSubject.asObservable().distinctUntilChanged();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json'
  });

  constructor(private http: HttpClient, private authService: AuthService) {}

  public getPaymentMethods(cart: Cart): Observable<any> {
    const userSessionCookies: any = this.authService.getUserRegistrationCookies();
    const header = new HttpHeaders({
      WCToken: userSessionCookies.WCToken,
      WCTrustedToken: userSessionCookies.WCTrustedToken
    });
    console.log(`${environment.api.gwApiGee}/order/details/${cart.orderNumber}`)
    console.log({
      WCToken: userSessionCookies.WCToken,
      WCTrustedToken: userSessionCookies.WCTrustedToken
    })
    return this.http.get(
      `${environment.api.gwApiGee}/order/details/${cart.orderNumber}`,
      { headers: header }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  public getPaymentMethodsWithPiAmount(
    cart: Cart,
    piAmount: number
  ): Observable<any> {
    
    const userSession: any = this.authService.getUserRegistrationCookies();
    const header = new HttpHeaders({
      WCToken: userSession.WCToken,
      WCTrustedToken: userSession.WCTrustedToken
    });

    return this.http.get(
      `${environment.api.gwApiGee}/order/details/${
        cart.orderNumber
      }?piAmount=${piAmount}`,
      { headers: header }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  public getCardBrand(cardNumber: string): Observable<any> {
    return this.http.get(
      `${environment.api.gwApiGee}/order/getCardBrandToken/${cardNumber}`,
      { headers: this.headers }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  public payWithCreditList(cpf: number): Observable<any> {
    return this.http.get(
      `${environment.api.gwApiGee}/customer/balance/${cpf}`,
      { headers: this.headers }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  public confirmCreditCardAndCreditListPayment(payment: any): Observable<any> {
    const userSession: any = this.authService.getUserRegistrationCookies();
    
    const header = new HttpHeaders({
      WCToken: userSession.WCToken,
      WCTrustedToken: userSession.WCTrustedToken
    });

    return this.http.post(
      `${environment.api.gwApiGee}/order/listPayment`,
      payment,
      { headers: header }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  public getCardAmount(orderNumber, amount, opt): Observable<any> {
   
    const wcToken = this.authService.getUserWCToken();
    const wcTrustedToken = this.authService.getUserWCTrustedToken();
    const header = new HttpHeaders({
      WCToken: wcToken,
      WCTrustedToken: wcTrustedToken
    });

    return this.http.get(
      `${
        environment.api.gwApiGee
      }/orderredemption/getPayments/${orderNumber}/${amount}`,
      { headers: header }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  public confirmCreditCardPayment(payment: any) : Observable<any>{
    
    const userSession: any = this.authService.getUserRegistrationCookies();
    const header = new HttpHeaders({
      WCToken: userSession.WCToken,
      WCTrustedToken: userSession.WCTrustedToken
    });

    return this.http.post(
      `${environment.api.gwApiGee}/order/payment`,
      payment,
      { headers: header }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  public confirmeBankSLipPayment(payment: any) : Observable<any>{
    
    const userSession: any = this.authService.getUserRegistrationCookies();
    const header = new HttpHeaders({
      WCToken: userSession.WCToken,
      WCTrustedToken: userSession.WCTrustedToken
    });
    return this.http.post(
      `${environment.api.gwApiGee}/order/payment`,
      payment,
      { headers: header }
    )
    .map(response => {
      return response;
    })
    .catch((error: any) => {
      return Observable.throw(error);
    });
  }

  /**
   * @description
   * Notifies all listener with totalValue.
   * @param totalValue - Value to be notified.
   */
  notify(totalValue: number): void {
    this.totalCartSubject.next(totalValue);
  }

}
