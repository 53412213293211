import { Injectable } from '@angular/core';
import { NClearSale } from '../../models/clear-sale-mapper.model';

declare const csdm: any;

@Injectable({
    providedIn: 'root'
})
export class ClearSaleMapperService {

    pendingRequests = [];
    isCSDMLoaded = false;

    constructor() { }

    sendMarker = (marker: NClearSale.EMarker): void =>
        this.sendBase(() => csdm('send', marker))

    sendProductMarker = (productSku: string, shortDescription: string): void =>
        this.sendBase(() => csdm('send',
            NClearSale.EMarker.Product,
            `sku=${productSku}, name=${shortDescription}`))

    sendSearchMarker = (keyWords: string, pageNumber: number): void =>
        this.sendBase(() => csdm('send',
            NClearSale.EMarker.Search,
            `key-words=${keyWords}, page-number=${pageNumber}`))

    sendPurchaseMarker = ( purchaseCode: string, paymentType: NClearSale.EPaymentType): void =>
       this.sendBase(() => csdm('send',
            NClearSale.EMarker.PurchaseConfirmation,
            `code=${purchaseCode}, payment-type=${paymentType}`))

    setCSDMLoaded = (): void => {
        this.isCSDMLoaded = true;
        this.pendingRequests.forEach((func) => {
            if (typeof func === 'function') func();
        });
        this.pendingRequests = [];
    };

    private sendBase(func: any) {
        if (this.isCSDMLoaded) {
            func();
        } else {
            this.pendingRequests.push(func);
        }
    }
}
