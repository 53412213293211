import { DialogService } from './../services/dialog.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,
                private router: Router,
                private dialogAlertService: DialogService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                const queryString = window.document.location.search.substring(1)
                let searchParams = {}
                if (queryString)
                    searchParams = JSON.parse('{"' + decodeURI(queryString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
                this.authService.logout();
                this.router.navigate(['/home'], { queryParams: searchParams });
            }
            if (err.status === 0 || err.status === 500) {
            }
            return throwError(err);
        }));
    }
}
