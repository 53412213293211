import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'escapeHtml' })
export class EscapeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content) {

    if (!content) {
      return;
    }
    if (content.includes('BEGIN')) {
      const contNew = content.replace(/<!--\s*([\s\S]*?)\s*-->/g, '');
      return this.sanitizer.bypassSecurityTrustHtml(contNew);
    } else if (content) {
      return this.sanitizer.bypassSecurityTrustHtml(content);
    }
  }
}
