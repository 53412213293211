import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

@Component({
  selector: "lc-partners-social",
  templateUrl: "./partners-social.component.html",
  styleUrls: ["./partners-social.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnersSocialComponent implements OnInit {
  constructor(private breakPoints: BreakpointObserver) {}
  isMobile: boolean;
  ngOnInit(): void {
    this.breakPoints.observe("(max-width:476px)").subscribe((result) => {
      this.isMobile = result.matches;
    });
  }
}
