import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs/Rx";
import { environment } from "../../environments/environment";
import { Cart } from "../models/cart.model";
import { Product } from "../models/product.model";
import { Quota } from "../models/quota.model";
import { AuthService } from "./auth.service";
import { LocalStorageService } from "./local-storage.service";

@Injectable()
export class OrderService {
  private auth: any;
  private headers = new HttpHeaders({
    "content-type": "application/json",
    Accept: "application/json",
  });
  private reloadOrder$ = new Subject();
  private cachedOrder: Cart = null;
  private readonly CART_STORAGE = 'cart';

  get cartStorage(): string {
    return this.CART_STORAGE;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) {}

  get listenReloadOrder() {
    return this.reloadOrder$.asObservable();
  }

  reloadOrder() {
    this.reloadOrder$.next();
  }

  currentOrder(): Observable<Cart> {
    const orderNumber = this.localStorage.getFromLocal("order-number");
    if(orderNumber == null){
      this.newOrder();
    }else{
      return this.http
      .get<any>(
        `${environment.api.gwApiGee}/order/${orderNumber}`,
        { headers: this.headers }
      )
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
    }
  }

  setCachedOrder(order: Cart) {
    this.cachedOrder = order;
  }

  public newOrder(): Observable<Cart> {
    return this.http
      .post<any>(
        `${environment.api.gwApiGee}/order`,
        { force: true },
        { headers: this.headers }
      )
      .map((response) => {
        let orderNumber = response.orderNumber;
        this.localStorage.saveInLocal("order-number", orderNumber);
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public getFreightValue(cart: Cart): Observable<any> {
    return this.http
      .get<any>(
        `${environment.api.gwApiGee}/order/freight/${cart.orderNumber}`,
        { headers: this.headers }
      )
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public updateOrder(cart: Cart): Observable<any> {
    return this.http
      .post<any>(`${environment.api.gwApiGee}/order`, cart, {
        headers: this.headers,
      })
      .map((response) => {
        this.localStorage.saveInLocal(this.cartStorage, response);
        let orderNumber  = response.orderNumber;
        this.localStorage.saveInLocal("order-number", orderNumber);
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public addProductOrder(cart: Cart, product: Product): Observable<any> {
    const headers = new HttpHeaders({
      Accept: "application/json",
      "content-type": "application/json",
    });

    if (cart.item == undefined) {
      cart.item = cart.products[0].sku;
    }

    const cartProduct = {
      orderNumber: cart.orderNumber,
      item: cart.item,
      quantity: 1,
      productToInsert: product,
    };

    return this.http
      .post<any>(`${environment.api.gwApiGee}/order/product`, cartProduct, {
        headers: headers,
      })
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public removeProductOrder(cart: Cart): Observable<any> {
    const options = {
      headers: this.headers,
      body: cart,
    };
    return this.http
      .delete<any>(`${environment.api.gwApiGee}/order/product`, options)
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public addQuotaOrder(cart: Cart): Observable<any> {
    const cartQuota = {
      orderNumber: cart.orderNumber,
      item: cart.item,
      quantity: cart.quantity,
    };

    return this.http
      .post<any>(`${environment.api.gwApiGee}/order/quota`, cartQuota, {
        headers: this.headers,
      })
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public removeQuotaOrder(cart: Cart): Observable<any> {
    const options = {
      headers: this.headers,
      body: cart,
    };
    return this.http
      .delete<any>(`${environment.api.gwApiGee}/order/quota`, options)
      .map((response) => {
        return response;
      })
      .catch((error: any) => {
        return Observable.throw(error);
      });
  }

  public addMessageToOrder(message: any, cartInfo: any) {
    const body = {
      messageId: message.internalId,
      messageFrom: message.messageFrom,
      messageText: message.messageText,
      orderNumber: cartInfo.orderNumber,
      weddingListNumber : cartInfo.weddingListNumber
    };
    return this.http.post<any>(`${environment.api.gwApiGee}/order`, body, {
      headers: this.headers,
    });
  }

  get getAuth() {
    this.auth = this.authService.getUserRegistration();
    return this.auth;
  }

  sendToCache(
    method: "add" | "remove",
    cart: Cart,
    product?: Product,
    quota?: Quota
  ) {
    const cartStrategies = {
      add: [
        {
          condition: !!product,
          action: (): any => {
            const products = [...cart.products, product];
            const totalValue = products.reduce(
              (acc, crr) => acc + crr.priceOffer,
              0
            );
            const partialValue = totalValue;
            const orderType = "PRODUCTS";
            return {
              ...cart,
              products,
              totalValue,
              partialValue,
              orderType,
            };
          },
        },
        {
          condition: !!quota,
          action: (): any => {
            const quotas = [...cart.quotas, quota];
            const totalValue = quotas.reduce((acc, crr) => acc + crr.value, 0);
            const partialValue = totalValue;
            const orderType = "QUOTAS";
            return {
              ...cart,
              quotas,
              totalValue,
              partialValue,
              orderType,
            };
          },
        },
      ],
      remove: [
        {
          condition: !!product,
          action: (): any => {
            const products = cart.products.filter(
              (current) => current !== product
            );
            const totalValue = products.reduce(
              (acc, crr) => acc + crr.priceOffer,
              0
            );
            const partialValue = totalValue;
            const orderType = "PRODUCTS";
            return {
              ...cart,
              products,
              totalValue,
              partialValue,
              orderType,
            };
          },
        },
        {
          condition: !!quota,
          action: (): any => {
            const quotas = cart.quotas.filter((current) => current !== quota);
            const totalValue = quotas.reduce((acc, crr) => acc + crr.value, 0);
            const partialValue = totalValue;
            const orderType = "QUOTAS";
            return {
              ...cart,
              quotas,
              totalValue,
              partialValue,
              orderType,
            };
          },
        },
      ],
    };
    const { action } = cartStrategies[method].find(
      ({ condition }) => condition
    );
    const partialCart = action();
    this.setCachedOrder(partialCart);
  }
}
