function load() {
  (function () {
    let node = document.createElement("script");
    node.src =
      "http://maps.googleapis.com/maps/api/js?key=AIzaSyBy6MRs5ymd5H2ZUWwsa6nFQBluEPLFVxg";
    node.type = "text/javascript";
    node.async = true;
    node.id = "googleMaps";
    document.getElementsByTagName("body")[0].appendChild(node);
    // console.log("Google Maps actived!")
  })();
}

function call() {
  return `${load.name}()`;
}

export { load, call };
