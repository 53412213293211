import { Directive, HostListener, Renderer2 } from "@angular/core";
import { AsyncScriptService } from "../async-script.service";
import { load, call } from "./getsitecontrol.script";

@Directive({ selector: "[getSiteControl]" })
export class GetSiteControlScriptDirective {
  constructor(
    private asyncScriptService: AsyncScriptService,
    private renderer: Renderer2
  ) {}

  @HostListener("window:load", ["$event"])
  onLoad(): void {
    this.asyncScriptService.addRawScript(
      `${load.toString()}; ${call()}`,
      this.renderer
    );
  }
}
