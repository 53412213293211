function load() {
    (function(){
        let node = document.createElement("script");
        node.src = "https://www.google.com/recaptcha/api.js?render=6Ldc-FkgAAAAAIhr8Dh-CmFOy4Qgywt1S2eA9dXf";
        node.type = "text/javascript";
        node.async = true;
        node.id = "recaptcha";
        document.getElementsByTagName("body")[0].appendChild(node);
        // console.log("Recaptcha actived!");
    })()
}

function call() {
    return `${load.name}()`;
}

export { load, call };
