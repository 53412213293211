import { Inject, Injectable } from "@angular/core";
import { SESSION_STORAGE, WebStorageService } from "angular-webstorage-service";

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService{
    constructor(@Inject(SESSION_STORAGE) private storage:WebStorageService){}

    public saveInSession(key: string, val: any): void {
        this.storage.set(key, val);
    }

    public getFromSession(key): any {
        return this.storage.get(key);
    }

    public removeInSession(key: string): void {
        this.storage.remove(key);
    }

    public clear(): void {
        sessionStorage.clear();
    }
}