import { NgModule } from "@angular/core";
import { AntiFlickerScriptDirective } from "./anti-flicker/anti-flicker-script.directive";
import { LoadScriptsComponent } from "./load-scripts/load-scripts.component";
import { CommonModule } from "@angular/common";
import { ClearSaleScricptDirective } from "./clear-sale/clear-sale-script.directive";
import { GetSiteControlScriptDirective } from "./getsitecontrol/getsitecontrol-script.directive";
import { GoogleAnalyticsScriptDirective } from "./google-analytics/google-analytics-script.directive";
import { GoogleTagManagerScriptDirective } from "./google-tag-manager/google-tag-manager-script.directive";
import { LejourScriptDirective } from "./lejour/lejour-script.directive";
import { OneTrustScriptDirective } from "./one-trust/one-trust-script.directive";
import { GoogleMapsScriptDirective } from "./google-maps/google-maps-script.directive";
import { RecaptchaScriptDirective } from "./recaptcha/recaptcha-script.directive";

@NgModule({
  declarations: [
    AntiFlickerScriptDirective,
    ClearSaleScricptDirective,
    GetSiteControlScriptDirective,
    GoogleAnalyticsScriptDirective,
    GoogleTagManagerScriptDirective,
    LejourScriptDirective,
    OneTrustScriptDirective,
    GoogleMapsScriptDirective,
    RecaptchaScriptDirective,
    LoadScriptsComponent,
  ],
  imports: [CommonModule],
  exports: [LoadScriptsComponent],
})
export class AsyncScriptsModule {}
