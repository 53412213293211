import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "lc-floating",
  templateUrl: "./floating.component.html",
  styleUrls: ["./floating.component.scss"],
})
export class FloatingComponent implements OnInit {
  constructor(private breakPoint: BreakpointObserver) {}

  isMobile: boolean;
  ngOnInit(): void {
    this.breakPoint
      .observe("(max-width:476px)")
      .subscribe((result) => (this.isMobile = result.matches));
  }

  scrollTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
