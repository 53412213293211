function load() {
  (function () {
    let dataLayer = [];
    let node = document.createElement("script");
    node.src = "https://www.googletagmanager.com/gtag/js?id=UA-9188969-7";
    node.type = "text/javascript";
    node.async = true;
    node.id = "googleAnalytics";
    document.getElementsByTagName("body")[0].appendChild(node);

    window["dataLayer"] = window["dataLayer"] || [];
    function gtag(...a) {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "UA-9188969-7", {
      optimize_id: "GTM-MMRS6F9",
      send_page_view: false,
    });
    // console.log("Google analytics actived!");
  })();
}

function call() {
  return `${load.name}()`;
}

export { load, call };
