import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {DialogAlertComponent} from '../shared/dialog-alert/dialog-alert.component';
import {Location} from '@angular/common';
import { Router } from '@angular/router';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog,
              private _location: Location,
              private router: Router) {

  }

  public openAlertDialog(title: string, message: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    let dialogRef: MatDialogRef<DialogAlertComponent>;
    dialogRef = this.dialog.open(DialogAlertComponent, dialogConfig);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
  }

  public openAlertDialogAction(title: string, message: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    let dialogRef: MatDialogRef<DialogAlertComponent>;
    dialogRef = this.dialog.open(DialogAlertComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(results => {
      this.router.navigate(['/find-couple']);
    });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
  }
}
