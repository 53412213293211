import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2 } from "@angular/core";

@Injectable({ providedIn: "root" })
export class AsyncScriptService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  addRawScript(
    content: string,
    renderer: Renderer2,
    posLoad?: any,
    time: number = 10000
  ) {
    const script = renderer.createElement("script") as HTMLScriptElement;
    script.type = "text/javascript";
    script.defer = true;
    script.textContent = content;
    this.loadWithDelay(function (document: Document) {
      renderer.appendChild(document.body, script);
      if (posLoad) posLoad();
    }, time);
  }

  addSrcScript(
    src: string,
    renderer: Renderer2,
    attributes?: Array<any>,
    time: number = 10000
  ) {
    const script = renderer.createElement("script") as HTMLScriptElement;
    script.type = "text/javascript";
    script.src = src;
    script.defer = true;

    if (attributes) {
      attributes.forEach((attr) => {
        script.setAttribute(attr.name, attr.value);
      });
    }

    this.loadWithDelay(
      (document: Document) => renderer.appendChild(document.body, script),
      time
    );
  }

  addRawHeaderScript(content: string, renderer: Renderer2, time: number = 7000) {
    const script = renderer.createElement("script") as HTMLScriptElement;
    script.type = "text/javascript";
    script.defer = true;
    script.textContent = content;
    this.loadWithDelay(
      (document: Document) => renderer.appendChild(document.head, script),
      time
    );
  }

  loadWithDelay(func: any, time: number) {
    (function (document: any) {
      const timeout = setTimeout(() => {
        func(document);
        clearTimeout(timeout);
      }, time);
    })(this.document);
  }
}
