import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  templateUrl: "./modal-info.component.html",
  styleUrls: ["./modal-info.component.scss"],
  selector: "lc-modal-info",
})
export class ModalInfoComponent {
  constructor(private dialogRef: MatDialogRef<ModalInfoComponent>) {}
  close() {
    this.dialogRef.close();
  }
}
