import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoadingService } from "../services/loading.service";

@Injectable({
  providedIn: "root",
})
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}
  private requests: HttpRequest<any>[] = [];

  private removeRequest(req: HttpRequest<any>) {
    const index = this.requests.indexOf(req);
    this.requests.splice(index, 1);
    const hasRequests = this.requests.length > 0;
    this.loadingService.isLoading.next(hasRequests);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const loadingUrls = ["order"];
    const isLoadingUrl =
      loadingUrls.findIndex((url) => req.url.includes(url)) > -1;
    if (isLoadingUrl) {
      this.requests.push(req);
      this.loadingService.isLoading.next(true);

      return new Observable((observer) => {
        const subscription = next.handle(req).subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },
          (err) => observer.error(err),
          () => {
            observer.complete();
          }
        );
        subscription.add(() => this.removeRequest(req));
      });
    } else {
      return next.handle(req);
    }
  }
}
