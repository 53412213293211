import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LinkList } from "src/app/models/LinkList.model";

@Component({
  selector: "lc-links",
  templateUrl: "./links.component.html",
  styleUrls: ["./links.component.scss"],
})
export class LinksComponent {
  @Input() list: LinkList;
}
