import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LinkList } from "src/app/models/LinkList.model";

@Component({
  selector: "lc-section-links",
  templateUrl: "./section-links.component.html",
  styleUrls: ["./section-links.component.scss"],
})
export class SectionLinksComponent {
  @Input() linkSections: LinkList[];
}
