import { Component, OnInit } from "@angular/core";

@Component({
  selector: "lc-load-scripts",
  templateUrl: "./load-scripts.component.html",
})
export class LoadScriptsComponent implements OnInit {
  show = false;

  ngOnInit(): void {
    this.show = true;
  }
}
