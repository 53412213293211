import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const userSession = this.authService.getUserRegistration();
        if (userSession && userSession.WCToken) {
            req = req.clone({
                setHeaders: {
                    WCToken: `${userSession.WCToken}`,
                    WCTrustedToken: `${userSession.WCTrustedToken}`
                }
            });
        }
        return next.handle(req);
    }
}
