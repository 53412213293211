import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { HomeSpotService } from "src/app/services/homespot.service";

@Component({
  selector: "lc-spot",
  templateUrl: "./spot.component.html",
  styleUrls: ["./spot.component.scss"],
})
export class SpotComponent implements OnInit, OnDestroy {

  constructor(private spotService: HomeSpotService) {}

  @Input() spotName: string;
  
  public spotContent: any;
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    
    const subscription = this.spotService
      .getSpot(this.spotName)
      .first()
      .subscribe((response) => {
        
        let content = '';
        const data = response.split('<body');

        if (data.length === 2) {
            content = `<html><head></head><body${data[1]}`;
        } else {
            content = response;
        }
        this.spotContent = content;
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
