import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { DOCUMENT } from '@angular/common';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuard implements CanActivate {

  private adminToken: string;

  constructor(private authService: AuthService,
              private router: Router,
              private oauthService: OAuthService,
              @Inject(DOCUMENT) private document: Document) {
    this.adminToken = this.oauthService.getAccessToken();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userSession: any = this.authService.getUserRegistration();
    const userSessionCookies: any = this.authService.getUserRegistrationCookies();
    const userWCToken: any = userSessionCookies.WCToken;
    const userWCTrustedToken: any = userSessionCookies.WCTrustedToken;

    if (userSession && userWCToken && userWCTrustedToken) {
      return true;
    } else if (this.isAdmin) {
      return true;
    } else {
      this.router.navigate(['/home', {outlets: {modal: 'login'}}], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }

  get isAdmin() {
    const isAdmin = this.document.location.href.includes('isAdmin=true');
    return this.adminToken && isAdmin;
  }

}
