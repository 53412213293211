import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SpotParams } from "../models/SpotParams.model";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class HomeSpotService {
  constructor(private httpClient: HttpClient) {}

  private url = environment.urlSpot;

  getSpot(spotName:string) {
    return this.httpClient.get(`${this.url}/${spotName}`, {
      responseType: "text",
    });
  }
}
