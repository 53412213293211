export const environment = {
    production: true,
    sessionName: 'userRegisterSession',
    urlNovaLista: 'https://listadecasamento.fastshop.com.br/lista',
    urlEmotionmePageCouple: 'http://nossosite.fastshop.com.br',
    urlPageCouple: 'https://listadecasamento.fastshop.com.br/lista/',
    urlPanelCouple: 'https://listadecasamento.fastshop.com.br/painel/',
    urlAdm: 'https://listadecasamento.fastshop.com.br/painel/pages/administrator',
    urlListAdmin: 'https://listavirtual.fastshop.com.br/login/signin',
    urlLoginAdmin: 'https://www.fastshop.com.br/web/autoatendimento/acompanhar-pedidos',
    urlSpot: 'https://apigw.fastshop.com.br/proxy-wcs/v0/wcs/resources/v1/spots',
    home: {
        urlCriarLista: '/c/create-list',
        urlInstagram: 'https://instagram.com/fastshopcasamentos',
        urlFaqListaCasamento: 'https://imagens.fastshop.com.br/imgbanner/FAQ.pdf',
        urlChat: 'https://www15.directtalk.com.br/static/beta/admin/partials/misc/dtbot_preview.html?token=3ca2834d-c968-4c85-8399-4cc66bacc242&botopen',
        urlFornecedor: 'https://cp.lejour.com.br/fastshop/fornecedores',
        urlAcessarConta: 'https://listavirtual.fastshop.com.br/login/signin',
        urlPesquisaCasal: 'https://listadecasamento.fastshop.com.br/ListaCasamento/ListaCasamentoBusca.aspx',
        videoYoutube: 'https://www.youtube.com/embed/crC0_uSCKVA'
    },
    product: {
        imgUrl: 'https://prdresources1-a.akamaihd.net',
        imageUrlDomain: 'https://io.fastshop.com.br',
        imageUrlWebApp: 'https://io.fastshop.com.br/wcsstore'
    },
    api: {
        emotionMe: 'https://listavirtual.fastshop.com.br/nlc',
        gwApiGee: 'https://apigw.fastshop.com.br/wedding/v0',
        gwApiLocal: 'http://172.30.34.75:8000/nlc',
        wcs: 'https://www.fastshop.com.br/wcs/resources/v1',
        lc_ibge:'https://apigw.fastshop.com.br/locate/v0/buscalocalidades',
        upload_image: 'https://apigw.fastshop.com.br/file/v0/wedding',
        image_url: 'https://api.fastshop.com.br/v1/casamento'
    },
    openId: {
        issuer: 'https://hml-auth.kaive.com.br',
        redirectUri: 'http://listadecasamento.fastshop.com.br/',
        clientId: 'wedding-list-manager.spa',
        scope: 'openid profile fastshop.wedding-list',
        responseType: 'id_token token',
        postLogoutRedirectUri: 'http://listadecasamento.fastshop.com.br/'
    },
    collectPoint: 'https://api.fastshop.com.br'
};