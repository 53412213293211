import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuestGuard implements CanActivate {

    constructor(private authService: AuthService,
                private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const userSessionCookies: any = this.authService.getUserRegistrationCookies();
        if (userSessionCookies && userSessionCookies.WCToken && userSessionCookies.WCTrustedToken) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.authService.deleteLocalSession();
        this.router.navigate(['/home', {outlets: {modal: 'login' }}], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
