function load(){
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j['async'] = true;
        j['src'] = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
        // console.log("Google tag manager actived!");
      })(window, document, "script", "dataLayer", "GTM-KMRKC4");
}

function call(){
    return `${load.name}()`;
}

export { load, call };