import { SessionStorageService } from 'src/app/services/session-storage.service';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Wedding } from '../models/wedding.model';
import { Departament } from '../models/departament.model';
import { CookieService } from 'ngx-cookie-service';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { States, City } from '../models/states.model';

@Injectable()
export class WeddingService implements OnInit {

    constructor(private http: HttpClient,
        private sessionStorage: SessionStorageService) {
    }

    ngOnInit(): void { }

    search(terms: Observable<any>) {
        return terms.debounceTime(400)
            .distinctUntilChanged()
            .switchMap(term => this.searchCouple(term));
    }

    searchCouple(term: any) {
        if (term.nameOrID || term.weddingDay || term.state || term.city) {
            let params = '';
            params += term.nameOrID ? `&text=${term.nameOrID}` : '';
            params += term.weddingDay ? `&weddingDay=${term.weddingDay}` : '';
            params += term.state ? `&state=${term.state}` : '';
            params += term.city ? `&city=${term.city}` : '';
            return this.http.get<any>(`${environment.api.gwApiGee}/weddinglist/search?${params}`);
        }
    }

    getWedding(url: string): Observable<any> {
        return this.http.get<any>(`${environment.api.gwApiGee}/weddinglist/search/${url}`);
    }

    addLocalWedding(wedding: Wedding): void {
        this.sessionStorage.saveInSession('couple-page', wedding);
    }

    delLocalWedding(): void {
        this.sessionStorage.removeInSession('couple-page');
    }

    getLocalWedding(): Wedding {
        return this.sessionStorage.getFromSession('couple-page');
    }

    getProductsCategories(wedding: Wedding): Observable<any> {
        // TODO: Migracao
        return this.http.get<any>(`${environment.api.gwApiGee}/products/categories/${wedding.id}`);
    }

    getWeddingProduct(wedding: Wedding, departament: Departament, page: number = 1, orderBy: string = null,
        category: string = null, entrys: string[] = null): Observable<any> {
        let params = '';
        page = page > 0 ? page - 1 : page;
        params += departament ? `&departmentId=${departament.departmentInternalId}` : '';
        params += orderBy ? `&sort=${orderBy}` : '';
        params += category ? `&wcsId=${category}` : '';
        if (entrys && entrys.length > 0) {
            entrys.map(entry => {
                params += `&filters=${entry}`;
            });
        }
        return this.http.get<any>(`${environment.api.gwApiGee}/products/${wedding.id}?page=${page}&${params}`);
    }

    getWeddingQuota(wedding: Wedding, orderBy: string = null, entrys: string[] = null): Observable<any> {
        let params = '';
        params += orderBy ? `&sort=${orderBy}` : '';
        if (entrys && entrys.length > 0) {
            entrys.map(entry => {
                params += `&filters=${entry}`;
            });
        }

        return this.http.get<any>(`${environment.api.gwApiGee}/profile/${wedding.id}/quotas/engaged?${params}`)
            .map(response => {
                return response;
            })
            .catch((error: any) => {
                return Observable.throw(error);
            });
    }

    getWeddingProductAll(wedding: Wedding, page: number = 999): Observable<any> {
        return this.http.get<any>(`${environment.api.gwApiGee}/products/${wedding.id}?page=${page}`);
    }

    getAboutUs(wedding: Wedding): Observable<any> {
        return this.http.get<any>(`${environment.api.gwApiGee}/about/${wedding.weddingListNumber}`);
    }

    getEvent(wedding: Wedding): Observable<any> {
        return this.http.get<any>(`${environment.api.gwApiGee}/event/${wedding.weddingListNumber}`);
    }

    getStates() {
        return this.http.get<States[]>(`${environment.api.lc_ibge}`)
    }

    getCityId(id: number) {
        return this.http.get<City[]>(`${environment.api.lc_ibge}/${id}/municipios`)
    }
}
