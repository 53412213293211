function load() {
  (function () {
    let node = document.createElement('script');
    node.src = 'https://privacyportal-cdn.onetrust.com/consent-receipt-scripts/scripts/otconsent-1.0.min.js';
    node.type = 'text/javascript';
    node.defer = true;
    node.id = "consent-receipt-script";
    node.setAttribute('data-document-language', 'true');
    node.setAttribute('data-domain-script', '3e1152bb-8f68-4ec4-bbfb-750ba61bc496');
    node.textContent = `triggerId = "trigger";
      identifierId = "inputEmail";
      confirmationId = "confirmation";
      token = "eyJhbGciOiJSUzUxMiJ9.eyJvdEp3dFZlcnNpb24iOjEsInByb2Nlc3NJZCI6IjhiMDc2NDEzLTM4YjctNGE4ZS05N2VlLTQ1MWUwNWM5Mzc3NSIsInByb2Nlc3NWZXJzaW9uIjozLCJpYXQiOiIyMDIwLTExLTA0VDEzOjIzOjAzLjY1IiwibW9jIjoiV0VCX0ZPUk0iLCJzdWIiOiJFbWFpbCIsImlzcyI6bnVsbCwidGVuYW50SWQiOiI3ZTdlM2UzMi03NDFmLTRlOGQtYjNlMi1hMGY3NzMwYzkzZTMiLCJkZXNjcmlwdGlvbiI6IkrDoSBjb21lw6dvdSBhIEdvbGRlbiBGcmlkYXkgbmEgRmFzdCBTaG9wLiBTw6NvIG9zIG1lbGhvcmVzIGRlc2NvbnRvcyBkbyBhbm8sIGNoZWdhbmRvIGF0w6kgODAlLiBTZSBpbmNyZXZhIHBhcmEgcmVjZWJlciBtYWlzIHByb21vw6fDtWVzLiIsImNvbnNlbnRUeXBlIjoiRk9STVNVQk1JVCIsImRvdWJsZU9wdEluIjpmYWxzZSwicmVjb25maXJtQWN0aXZlUHVycG9zZSI6ZmFsc2UsImF1dGhlbnRpY2F0aW9uUmVxdWlyZWQiOmZhbHNlLCJwb2xpY3lfdXJpIjpudWxsLCJhbGxvd05vdEdpdmVuQ29uc2VudHMiOmZhbHNlLCJwdXJwb3NlcyI6W3siaWQiOiIyOWE2NzdkNS04MjMyLTRjNzEtYWQ2Ny01Y2VhNjNmOWE1ZTgiLCJ2ZXJzaW9uIjoxLCJ0b3BpY3MiOltdLCJjdXN0b21QcmVmZXJlbmNlcyI6W119XSwibm90aWNlcyI6W10sImRzRGF0YUVsZW1lbnRzIjpbIkZpcnN0TmFtZSIsIkxhc3ROYW1lIl19.UMRn9xOgZxV00CAMppbfHQR4wIw5niZ2OU5f0Ip393fldmc2oL5FH8hPQCnM_Zth-0m0BOdINGD1AGQKxOW5t1bWvHpH1d8gzd6K2FZtSN-d06mYKPTCDMQyjlU73dIIGFxKhe3-yI00WwghtyK1kANLEj7ODk3lkHlmPhM2BtlsSlOHsuAHLUfnzHwMRRyMWMBz9Gdc-HT2bSdYhfkKIUC6-IK6p1k_Ir4WVBA-p8VF_uKljo5MBrVeMideLrixMqiiCw5AEkTNxufrE6AJ_mLzMGtMhnGupT0bv12Ietgxs916B5IqbaN3FUm_el0Fv6d5yq6Ntq8lMau2yPafCfdi0DW7kG-9tDTnCTuzKERjYBnwe3H7zFScvSd4LH7Xw8ckbj-QdkoLgXNj8NxayEeO4rhvEDxfImpBSaW39kaunpSvPBuGesewThpEklqo0tvnMHY-nE37OrpW9aGPSqfirKsmbz5bsn4f0c9jXjJ9Z2_4XafJYzfO41-TUsOmaNyfeX6PIX2fYo3qxchjdsgFruBBJIYBTAq0seMevsWXq5MXYacXSgC5p3VEHTfk-rrAObru_kF9mPJ5jO13lzIxs_Svcvzhia-J3CuXC1BX5NHKJ3eiJDPPvMBLL5zlIjnb0ukhWfKsMHe2DUL4UUzzBjcTCbeHNtxPeijZlpw";
      settings = "eyJyZWNlaXB0QXBpRW5kcG9pbnQiOiJodHRwczovL3ByaXZhY3lwb3J0YWwub25ldHJ1c3QuY29tL3JlcXVlc3QvdjEvY29uc2VudHJlY2VpcHRzIiwibWFudWFsVHJpZ2dlciI6ZmFsc2UsImNvbnRpbnVlT25FcnJvciI6dHJ1ZSwiZGF0YUVsZW1lbnRzIjpbeyJkYXRhRWxlbWVudCI6ImM1NjJiZGM0LTNlODYtNDJhOC1hYTgxLWQ3YzkzZWRkYTE2ZiIsImlkZW50aWZpZXJWYWx1ZXMiOiJGaXJzdE5hbWUiLCJpZGVudGlmaWVyQXR0cmlidXRlcyI6InZhbHVlIiwiZGF0YUVsZW1lbnROYW1lIjoiRmlyc3ROYW1lIiwic2tpcENvbnNlbnQiOnRydWUsImxhYmVscyI6W119LHsiZGF0YUVsZW1lbnQiOiIyOTY5NjBkOS1iZjQ2LTRlNGUtYjU2NS01NGY4MTcwYzI4NGYiLCJpZGVudGlmaWVyVmFsdWVzIjoiTGFzdE5hbWUiLCJpZGVudGlmaWVyQXR0cmlidXRlcyI6InZhbHVlIiwiZGF0YUVsZW1lbnROYW1lIjoiTGFzdE5hbWUiLCJza2lwQ29uc2VudCI6dHJ1ZSwibGFiZWxzIjpbXX1dfQ==";
      OneTrust.ScriptTag.Preferences = [{ "purpose": "29a677d5-8232-4c71-ad67-5cea63f9a5e8", "identifierAttributes": "value", "identifierValues": "Campanha de marketing" }];`;

    document.getElementsByTagName('body')[0].appendChild(node);
    // console.log("OneTrust actived!");
  })();
  (function OptanonWrapper() {})();
}

function call() {
  return `${load.name}()`;
}

export { load, call };
