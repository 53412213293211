function load() {
  (function (l, e, j, o, u, r) {
    if (l["ljpt"]) return;
    l["_ljpt"] = {};
    l["ljpt"] = function (k, v) {
      l["_ljpt"][k] = v;
    };
    u = e.createElement(j);
    u.src = o;
    r = e.getElementsByTagName(j)[0];
    r.id = "fast-shop";
    r.parentNode.insertBefore(u, r);
   
  })(window, document, "script", "//scripts.lejour.com.br/lightbox/index.js");
  window['ljpt']("partnerId", "fast-shop");
  // console.log("Lejour actived!");
}

function call() {
  return `${load.name}()`;
}

export { load, call };
