import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs/Rx";
import { Cart } from "../models/cart.model";
import { PaymentOption } from "../models/payment-option.model";
import { Product } from "../models/product.model";
import { Quota } from "../models/quota.model";
import { Wedding } from "../models/wedding.model";
import { AuthService } from "./auth.service";
import { LocalStorageService } from "./local-storage.service";
import { PaymentService } from "./payment.service";
import { WeddingService } from "./wedding.service";
import { OrderService } from "./order.service";
import { ActivatedRoute } from "@angular/router";
import { iif, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CartService {
  private readonly CART_STORAGE = "cart";
  private readonly CART_CUSTOMER_STORAGE = "cartCustomer";
  private readonly CART_PAYMENT_OPTIONS_STORAGE = "paymentOptions";

  public item: any;
  private item$: Subject<any> = new Subject();
  private $cartQuantity = new BehaviorSubject<number>(0);

  constructor(
    private localStorage: LocalStorageService,
    private weddingService: WeddingService,
    private authService: AuthService,
    private paymentService: PaymentService,
    private orderService: OrderService,
    private activedRoute: ActivatedRoute
  ) {
    if (!!this.orderService.currentOrder()) {
      this.orderService.currentOrder().subscribe((order) => {
        if (order.products.length) {
          this.$cartQuantity.next(order.products.length);
        }
      });
    }
  }

  get cartQuantity$() {
    return this.$cartQuantity.asObservable();
  }

  public setCartStorage(cart: Cart, wed: Wedding) {
    let wedding = this.weddingService.getLocalWedding();
    if (wedding == null) {
      wedding = wed;
    }
    const customer = this.authService.getUserRegistration();
    const cartQuantity = cart.products.length + cart.quotas.length;

    if (customer && !customer.message) {
      cart.customerEmail = customer.email
        ? customer.email
        : customer.customerEmail.email;
    }

    this.$cartQuantity.next(cartQuantity);
    cart.weddingListNumber = wedding.weddingListNumber;
    this.localStorage.saveInLocal(this.cartStorage, cart);
    this.paymentService.notify(cart.totalValue);
  }

  public getCartQuotasStorage(): Quota[] {
    const cart = this.getCartStorage();
    if (cart) {
      return cart.quotas ? cart.quotas : [];
    }
  }

  public getCartProductsStorage(): Product[] {
    const cart = this.getCartStorage();
    if (cart) {
      return cart.products ? cart.products : [];
    }
  }

  public getCartStorage(): Cart {
    return this.localStorage.getFromLocal(this.cartStorage);
  }

  public removeCartStorage() {
    this.localStorage.removeInLocal(this.cartStorage);
  }

  public productOrQuotaFailed(item: any) {
    this.item = item;
    this.item$.next({ itemFailed: this.item });
  }

  public getItemFailed(): Observable<any> {
    return this.item$.asObservable();
  }

  get cartStorage(): string {
    return this.CART_STORAGE;
  }

  get cartCustomerStorage(): string {
    return this.CART_CUSTOMER_STORAGE;
  }

  get cartPaymentStorage(): string {
    return this.CART_PAYMENT_OPTIONS_STORAGE;
  }

  public getCustomerCartStorage(): Cart {
    return this.localStorage.getFromLocal(this.cartCustomerStorage);
  }

  public putCustomerCartStorage(customer: any) {
    this.localStorage.saveInLocal(this.cartCustomerStorage, customer);
  }

  public getPaymentCartStorage(): PaymentOption {
    return this.localStorage.getFromLocal(this.cartPaymentStorage);
  }

  public putPaymentCartStorage(payment: any) {
    this.localStorage.saveInLocal(this.cartPaymentStorage, payment);
  }
}
