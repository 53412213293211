import { Directive, HostListener, Renderer2 } from "@angular/core";
import { AsyncScriptService } from "../async-script.service";
import { load, call } from "./google-maps.script";

@Directive({selector:"[googlemaps]"})
export class GoogleMapsScriptDirective{
    constructor(
        private asyncScriptService: AsyncScriptService,
        private renderer: Renderer2
      ) {}
    
      @HostListener("window:load", ["$event"])
      onLoad(): void {
        this.asyncScriptService.addRawScript(
          `${load.toString()}; ${call()}`,
          this.renderer
        );
      }
}