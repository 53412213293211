import { LoadingInterceptor } from "./interceptors/loading.interceptor";
import { AuthGuestGuard } from "./services/auth-guest.guard.";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
} from "ng2-currency-mask/src/currency-mask.config";
import { ElementScrollPercentageDirective } from "./shared/element-scroll/element-scroll-percentage.directive";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { OwlModule } from "ngx-owl-carousel";

import { AuthGuard } from "./services/auth.guard";
// Interceptors
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
// Services

import { OAuthModule } from "angular-oauth2-oidc";
import { NewLayoutModule } from "./new-layout/new-layout.module";
import { AsyncScriptsModule } from "./async-scripts/async-scripts.module";
import { StorageServiceModule } from "angular-webstorage-service";
import { CookieService } from "ngx-cookie-service";
import { OrderService } from "./services/order.service";
import { NestingSafePipe } from "./shared/pipe/nesting-safe.pipe";
import { CartService } from "./services/cart.service";
registerLocaleData(localePt);

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
};

@NgModule({
  declarations: [AppComponent, ElementScrollPercentageDirective],
  imports: [
    AsyncScriptsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OwlModule,
    OAuthModule.forRoot(),
    StorageServiceModule,
    NewLayoutModule,
  ],
  providers: [
    AuthGuard,
    AuthGuestGuard,
    OrderService,
    CartService,
    CookieService,
    NestingSafePipe,
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
