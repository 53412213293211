import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "lc-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private breakpoint: BreakpointObserver,
    private router: Router,
  ) {}

  headerAccountText = "Acesse sua conta";
  isHome = true;
  url: string;
  subscriptions = new Subscription();
  @Input() headerStyle;

  ngOnInit(): void {
    this.observeIsMobile();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  observeIsMobile() {
    const subscription = this.breakpoint
      .observe("(max-width:476px)")
      .subscribe((result) => {
        if (result.matches) {
          this.headerAccountText = "ENTRAR";
        }
      });
    this.subscriptions.add(subscription);
  }

 
  goLogin() {
    this.router.navigate([{ outlets: { modal: "login" } }]);
  }

  goToSpot(id: string) {
    document.getElementById(id).scrollIntoView();
  }
}
