import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LinkList } from "src/app/models/LinkList.model";

@Component({
  selector: "lc-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(private breakPoints: BreakpointObserver) {}

  isMobile: boolean;

  icons = {
    whatsapp: "assets/images/new-home/icons/icon-whatsapp.svg",
    info: "assets/images/new-home/icons/icon-info.svg",
    email: "assets/images/new-home/icons/icon-email.svg",
    phone: "assets/images/new-home/icons/icon-phone.svg",
  };

  linkSections: LinkList[] = [
    {
      title: "A Lejour",
      links: [
        { label: "Sobre nós", link: "https://lejour.com.br/sobre-nos" },
        {
          label: "Termos de uso",
          link: "https://ajuda.lejour.com.br/pt/articles/5746331-termo-de-uso-noivos",
        },
        {
          label: "Política de privacidade",
          link: "https://ajuda.lejour.com.br/pt/articles/4520457-politica-de-privacidade",
        },
      ],
    },
    {
      title: "Para o casal",
      links: [
        {
          label: "Site de casamentos",
          link: "https://lejour.com.br/site-de-casamento",
        },
        {
          label: "Lista de presentes",
          link: "https://lejour.com.br/lista-de-casamento",
        },
        { label: "Inspirações", link: "https://lejour.com.br/blog" },
      ],
    },
    {
      title: "Atendimento para o site e lista de casamento",
      links: [
        {
          label: "Whatsapp Lejour",
          icon: this.icons.whatsapp,
          iconAlt: "Ícone whatsapp",
          link: "https://api.whatsapp.com/send?phone=5511971377073&text=Ol%C3%A1,%20gostaria%20de%20saber%20o%20motivo%20do%20meu%20saldo%20Lejour%20n%C3%A3o%20estar%20ativo%20para%20resgate%20na%20Fast%20Shop",
        },
        {
          label: "Central de ajuda",
          icon: this.icons.info,
          iconAlt: "Ícone informações",
          link: "https://ajuda.lejour.com.br/pt/",
        },
        {
          label: "Suporte",
          icon: this.icons.email,
          iconAlt: "Ícone email",
          link: "mailto:suporte@lejour.com.br",
        },
      ],
    },
    {
      title: "Atendimento para resgate de produtos",
      links: [
        {
          label: "11 3232 2948 (informações)",
          icon: this.icons.whatsapp,
          iconAlt: "Ícone Whatsapp",
        },
        {
          label: "11 3003 3728 (Capitais e Regiões Metropolitanas)",
          icon: this.icons.phone,
          iconAlt: "Ícone contato",
        },
        {
          label: "0800 010 3728 (Demais localidades)",
          icon: this.icons.phone,
          iconAlt: "Ícone contato",
        },
      ],
    },
  ];

  ngOnInit(): void {
    this.breakPoints
      .observe("(max-width:476px)")
      .subscribe((result) => (this.isMobile = result.matches));
  }
}
