import {Injectable, Inject} from '@angular/core';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {

    constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {
    }

    public saveInLocal(key: string, val: any): void {
        this.storage.set(key, val);
    }

    public getFromLocal(key): any {
        return this.storage.get(key);
    }

    public removeInLocal(key: string): void {
        this.storage.remove(key);
    }

    public clear(): void {
        localStorage.clear();
    }
}
