import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NoticeComponent } from "./notice/notice.component";
import { NewLayoutComponent } from "./new-layout.component";
import { HeaderComponent } from "./header/header.component";
import { FloatingComponent } from "./floating/floating.component";
import { FooterModule } from "./footer/footer.module";
import { RouterModule } from "@angular/router";
import { DeferLoadModule } from "@trademe/ng-defer-load";
import { DialogService } from "../services/dialog.service";
import { NewHomeModule } from "../new-home/new-home.module";
import { MatDividerModule, MatIconModule } from "@angular/material";

@NgModule({
  declarations: [
    NoticeComponent,
    NewLayoutComponent,
    HeaderComponent,
    FloatingComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    FooterModule,
    RouterModule,
    DeferLoadModule,
    NewHomeModule
  ],
  providers:[DialogService],
  exports:[NewLayoutComponent]
})
export class NewLayoutModule {}
