import { Injectable } from '@angular/core';
import { NestingSafePipe } from '../shared/pipe/nesting-safe.pipe';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  private readonly _dataLayer: [Object];

  constructor(private nestingSafe: NestingSafePipe) {

    let dataLayer = this.nestingSafe.transform(window, 'dataLayer');

    if (!dataLayer) {
      dataLayer = new Array();
    }

    this._dataLayer = dataLayer;
    window['dataLayer'] = this._dataLayer;
  }

  public addGTM(obj: Object) {
    this._dataLayer.push(obj);
  }
}
