import { WebStorageService, SESSION_STORAGE } from "angular-webstorage-service";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { Wedding } from "../models/wedding.model";
import { SessionStorageService } from "./session-storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private domain = "fastshop.com.br";
  private nameSessionStorageField = environment.sessionName;
  private $isLoggedInListerner: Subject<boolean> = new Subject();

  public profile: any;

  private headers = new HttpHeaders({
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  });

  constructor(
    private http: HttpClient,
    private cookies: CookieService,
    private sessionStorageService: SessionStorageService
  ) {}

  public setPreRegistration(userSession: any): Observable<any> {
    return this.http.put(
      `${environment.api.gwApiGee}/preregistration/${userSession.id}`,
      userSession,
      { headers: this.headers }
    );
  }

  public getPreRegistration(email: string = ""): Observable<any> {
    return this.http.post<any>(
      `${environment.api.gwApiGee}/preregistration`,
      JSON.stringify({ email: email }),
      { headers: this.headers }
    );
  }

  // Autentica usuario e grava em local Storage
  public login(
    username: string,
    password: string,
    guest: boolean,
    token: string
  ): Observable<any> {
    let loginUrl: string;
    if (guest) {
      loginUrl = "login/guest";
    } else {
      loginUrl = "login";
    }
    return this.http
      .post<any>(
        `${environment.api.gwApiGee}/auth/${loginUrl}`,
        { document: username, password: password, tokenRecaptcha: token },
        { observe: "response", withCredentials: true, headers: this.headers }
      )
      .pipe(
        map((session) => {
          if (session && session.body.WCTrustedToken) {
            session.body.userLogin = session.body.cpf;
            session = session.body;
            this.saveRegisterUser(session);
            this.$isLoggedInListerner.next(true);
          }
        })
      );
  }

  public getUserInfoFromCookies(): Observable<any> {
    const wcToken = this.getCookie("WCToken");
    const wcTrustedToken = this.getCookie("WCTrustedToken");
    const header = new HttpHeaders({
      WCToken: wcToken,
      WCTrustedToken: wcTrustedToken,
    });
    return this.http
      .get<any>(`${environment.api.gwApiGee}/templates/resources/v1/customer`, {
        headers: header,
      })
      .switchMap((res) => {
        return this.getProfileByCpf(res.cpf);
      })
      .pipe(
        map((session) => {
          if (session) {
            session.WCToken = wcToken;
            session.WCTrustedToken = wcTrustedToken;
            this.saveRegisterUser(session);
            this.$isLoggedInListerner.next(true);
            return session;
          }
        })
      );
  }

  public getCookie(cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  public getProfileByCpf(cpf: String): Observable<any> {
    return this.http
      .get<any>(
        `${environment.api.gwApiGee}/weddinglist/findWeddingProfileByCpf/${cpf}`,
        { headers: this.headers }
      )
      .pipe(
        map((session) => {
          return session;
        })
      );
  }

  public hasWeddingList(cpf: string) {
    return this.http.get<boolean>(
      `${environment.api.gwApiGee}/hasWeddinglist`,
      { params: { cpf }, headers: { token: "fdfdfdfdf" } }
    );
  }

  // Remove Sessao do local Storage
  public logout(): Observable<any> {
    const authSession: any = this.getUserRegistrationCookies();
    const header = new HttpHeaders({
      WCToken: authSession.WCToken,
      WCTrustedToken: authSession.WCTrustedToken,
    });

    return this.http
      .post<any>(`${environment.api.gwApiGee}/auth/logout`, null, {
        headers: header,
      })
      .pipe(
        map((data) => {
          this.deleteLocalSession();
          this.$isLoggedInListerner.next(false);
        })
      );
  }

  public deleteLocalSession(): void {
    const couplePage = this.sessionStorageService.getFromSession("couple-page");
    const userRegisterSession = this.sessionStorageService.getFromSession(
      this.nameSessionStorageField
    );
    this.sessionStorageService.clear();
    if (couplePage) {
      this.sessionStorageService.saveInSession("couple-page", couplePage);
    }
    if (userRegisterSession && userRegisterSession.WCTrustedToken) {
      const tokenNumber = userRegisterSession.WCTrustedToken.split("%2C")[0];
      this.cookies.delete("WC_AUTHENTICATION_" + tokenNumber, "/", this.domain);
    }
    this.cookies.delete("WC_SESSION_ESTABLISHED", "/", this.domain);
    this.cookies.delete("WC_USER_LOGIN", "/", this.domain);
    this.cookies.delete("WC_USER_FIRST_NAME", "/", this.domain);
    this.cookies.delete("WCToken", "/", this.domain);
    this.cookies.delete("userLogin", "/", this.domain);
    this.cookies.delete("WCTrustedToken", "/", this.domain);
  }

  public deleteAnonymousUserCookies(): void {
    const userRegisterSession = this.sessionStorageService.getFromSession(
      this.nameSessionStorageField
    );
    if (userRegisterSession && userRegisterSession.WCTrustedToken) {
      const tokenNumber = userRegisterSession.WCTrustedToken.split("%2C")[0];
      this.cookies.delete("WC_AUTHENTICATION_" + tokenNumber, "/", this.domain);
    }
    this.cookies.delete("WC_SESSION_ESTABLISHED", "/", this.domain);
    this.cookies.delete("WC_USER_LOGIN", "/", this.domain);
    this.cookies.delete("WC_USER_FIRST_NAME", "/", this.domain);
    this.cookies.delete("WCToken", "/", this.domain);
    this.cookies.delete("userLogin", "/", this.domain);
    this.cookies.delete("WCTrustedToken", "/", this.domain);
  }

  // Verifica se existe Sessao de Usuario local
  public isUserSessionLocal(): boolean {
    const userRegisterSession = this.sessionStorageService.getFromSession(
      this.nameSessionStorageField
    );
    return userRegisterSession !== undefined && userRegisterSession !== null;
  }

  public saveRegisterUser(session: any): void {
    this.saveRegisterUserCookies(session);
    this.saveRegisterUserSession(session);
  }

  public saveRegisterUserSession(userSession: any): void {
    this.sessionStorageService.saveInSession(
      this.nameSessionStorageField,
      userSession
    );
  }

  private saveRegisterUserCookies(userSession: any): void {
    const expireTime: Date = new Date();
    const userLogin = {
      WCToken: userSession.WCToken,
      WCTrustedToken: userSession.WCTrustedToken,
      email: userSession.customer
        ? userSession.customer.email
        : userSession.email,
      nome: userSession.customer ? userSession.customer.name : userSession.nome,
      userID: userSession.userId,
    };
    expireTime.setTime(expireTime.getTime() + 3600 * 1000);
    const tokenNumber = userSession.WCTrustedToken.split("%2C")[0];
    document.cookie = `WCToken=${userSession.WCToken};${expireTime};domain=${this.domain};path=/;`;
    document.cookie = `WCTrustedToken=${userSession.WCTrustedToken};${expireTime};domain=${this.domain};path=/;`;
    document.cookie = `userLogin=${JSON.stringify(
      userLogin
    )};${expireTime};domain=${this.domain};path=/;`;
    this.cookies.set(
      "WC_SESSION_ESTABLISHED",
      "true",
      expireTime,
      "/",
      this.domain
    );
    this.cookies.set(
      "WC_AUTHENTICATION" + tokenNumber,
      userSession.WCTrustedToken,
      expireTime,
      "/",
      this.domain
    );
    this.cookies.set(
      "WC_USER_LOGIN",
      userSession.userLogin,
      expireTime,
      "/",
      this.domain
    );
    const userName = userSession.nome
      ? userSession.nome.trim().split(" ")[0]
      : userSession.customer.name.trim().split(" ")[0];
    this.cookies.set(
      "WC_USER_FIRST_NAME",
      userName,
      expireTime,
      "/",
      this.domain
    );
  }

  public registerUserSession(): void {
    if (!this.isUserSessionLocal()) {
      this.getPreRegistration().subscribe((userSession) => {
        userSession.alreadyAnswered = userSession.guestsNumber !== 0;
        this.saveRegisterUserSession(userSession);
      });
    }
  }

  public getUserRegistrationCookies() {
    return this.cookies.getAll();
  }

  public getUserRegistration() {
    const user = this.sessionStorageService.getFromSession(
      this.nameSessionStorageField
    );
    return user;
  }

  public getWeddingListNumber() {
    const user = this.sessionStorageService.getFromSession("couple-page");
    return user.weddingListNumber;
  }

  public getcpf() {
    const user = this.sessionStorageService.getFromSession(
      "userRegisterSession"
    );
    return user.cpf;
  }

  public getemail() {
    const user = this.sessionStorageService.getFromSession(
      "userRegisterSession"
    );
    return user.email;
  }

  public setUserRegistration(wedding: Wedding) {
    this.sessionStorageService.saveInSession(
      this.nameSessionStorageField,
      wedding
    );
  }

  public getIsLoggedinListerner() {
    return this.$isLoggedInListerner.asObservable();
  }

  public clearLastDepartmentsSelected() {
    const profile = this.getUserRegistration();
    profile.lastDepartmentsSelected = {};
    profile.lastProductWeddingListSelected = "";

    this.saveRegisterUserSession(profile);
  }

  public updateQuantityQuota(quantity: any) {
    const profile = this.getUserRegistration();
    profile.quantityQuotas = quantity;
    this.saveRegisterUserSession(profile);
  }

  public updateQuantityproducts(quantity: any) {
    const profile = this.getUserRegistration();
    profile.qntProducts = quantity;
    this.saveRegisterUserSession(profile);
  }

  public updateWeedingReadyList(w: Wedding) {
    this.setUserRegistration(w);
  }

  public updateWeeding(w: Wedding) {
    if (w === undefined) {
      return w;
    }

    if (w.cpf == undefined) {
      return undefined;
    }

    if (this.profile == undefined || this.profile.customer == undefined) {
      this.profile = this.getUserRegistration();
      w.id = this.profile.id == undefined ? w.id : this.profile.id;
      w.email = this.profile.email == undefined ? w.email : this.profile.email;
      w.cpf = this.profile.cpf == undefined ? w.cpf : this.profile.cpf;
      w.userId =
        this.profile.userId == undefined ? w.userId : this.profile.userId;
      w.weddingDay =
        this.profile.weddingDay == undefined
          ? w.weddingDay
          : this.profile.weddingDay;
      w.giftValue =
        this.profile.giftValue == undefined
          ? w.giftValue
          : this.profile.giftValue;
      w.quotaValue =
        this.profile.quotaValue == undefined
          ? w.quotaValue
          : this.profile.quotaValue;
      w.myName =
        this.profile.myName == undefined ? w.myName : this.profile.myName;
      w.myGender =
        this.profile.myGender == undefined ? w.myGender : this.profile.myGender;
      w.partnerName =
        this.profile.partnerName == undefined
          ? w.partnerName
          : this.profile.partnerName;
      w.partnerGender =
        this.profile.partnerGender == undefined
          ? w.partnerGender
          : this.profile.partnerGender;
      w.termsOfUseAccepted =
        this.profile.termsOfUseAccepted == undefined
          ? w.termsOfUseAccepted
          : this.profile.termsOfUseAccepted;
      w.weddingListNumber =
        this.profile.weddingListNumber == undefined
          ? w.weddingListNumber
          : this.profile.weddingListNumber;
      w.quantityQuotas =
        this.profile.quantityQuotas == undefined
          ? w.quantityQuotas
          : this.profile.quantityQuotas;
      w.published =
        this.profile.published == undefined
          ? w.published
          : this.profile.published;
      w.qntGuests =
        this.profile.qntGuests == undefined
          ? w.qntGuests
          : this.profile.qntGuests;
      w.weddingUrl =
        this.profile.weddingUrl == undefined
          ? w.weddingUrl
          : this.profile.weddingUrl;
      w.sellerId =
        this.profile.sellerId == undefined ? w.sellerId : this.profile.sellerId;
      w.partnerFullName =
        this.profile.partnerFullName == undefined
          ? w.partnerFullName
          : this.profile.partnerFullName;
      w.partnerEmail =
        this.profile.partnerEmail == undefined
          ? w.partnerEmail
          : this.profile.partnerEmail;
      w.partnerRg =
        this.profile.partnerRg == undefined
          ? w.partnerRg
          : this.profile.partnerRg;
      w.partnerCpf =
        this.profile.partnerCpf == undefined
          ? w.partnerCpf
          : this.profile.partnerCpf;
      w.lastDepartmentsSelected =
        this.profile.lastDepartmentsSelected == undefined
          ? {}
          : this.profile.lastDepartmentsSelected;

      w.customer = {
        email:
          this.profile.customer.email == undefined
            ? w.customer.email
            : this.profile.customer.email,
        emailVerify:
          this.profile.customer.emailVerify == undefined
            ? w.customer.emailVerify
            : this.profile.customer.emailVerify,
        streetName:
          this.profile.customer.streetName == undefined
            ? w.customer.streetName
            : this.profile.customer.streetName,
        number:
          this.profile.customer.number == undefined
            ? w.customer.number
            : this.profile.customer.number,
        housingType:
          this.profile.customer.housingType == undefined
            ? w.customer.housingType
            : this.profile.customer.housingType,
        state:
          this.profile.customer.state == undefined
            ? w.customer.state
            : this.profile.customer.state,
        city:
          this.profile.customer.city == undefined
            ? w.customer.city
            : this.profile.customer.city,
        country:
          this.profile.customer.country == undefined
            ? w.customer.country
            : this.profile.customer.country,
        zipCode:
          this.profile.customer.zipCode == undefined
            ? w.customer.zipCode
            : this.profile.customer.zipCode,
        telephone:
          this.profile.customer.telephone == undefined
            ? w.customer.telephone
            : this.profile.customer.telephone,
        name:
          this.profile.customer.name == undefined
            ? w.customer.name
            : this.profile.customer.name,
        cpf:
          this.profile.customer.cpf == undefined
            ? w.customer.cpf
            : this.profile.customer.cpf,
        district:
          this.profile.customer.district == undefined
            ? w.customer.district
            : this.profile.customer.district,
        gender:
          this.profile.customer.gender == undefined
            ? w.customer.gender
            : this.profile.customer.gender,
        birthday:
          this.profile.customer.birthday == undefined
            ? w.customer.birthday
            : this.profile.customer.birthday,
        receiveEmailFast:
          this.profile.customer.receiveEmailFast == undefined
            ? w.customer.receiveEmailFast
            : this.profile.customer.receiveEmailFast,
        receiveSMSFast:
          this.profile.customer.receiveSMSFast == undefined
            ? w.customer.receiveSMSFast
            : this.profile.customer.receiveSMSFast,
        receiveEmailOthers:
          this.profile.customer.receiveEmailOthers == undefined
            ? w.customer.receiveEmailOthers
            : this.profile.customer.receiveEmailOthers,
        //opcionais
        complement:
          this.profile.customer.complement == undefined
            ? w.customer == undefined
              ? ""
              : w.customer.complement
            : this.profile.customer.complement,

        cellphone:
          this.profile.customer.cellphone == undefined
            ? w.customer == undefined
              ? ""
              : w.customer.cellphone
            : this.profile.customer.cellphone,

        rg:
          this.profile.customer.rg == undefined
            ? w.customer == undefined
              ? ""
              : w.customer.rg
            : this.profile.customer.rg,

        companyName:
          this.profile.customer.companyName == undefined
            ? w.customer == undefined
              ? ""
              : w.customer.companyName
            : this.profile.customer.companyName,

        tradeName:
          this.profile.customer.tradeName == undefined
            ? w.customer == undefined
              ? ""
              : w.customer.tradeName
            : this.profile.customer.tradeName,

        ie:
          this.profile.customer.ie == undefined
            ? w.customer == undefined
              ? ""
              : w.customer.ie
            : this.profile.customer.ie,

        cnpj:
          this.profile.customer.cnpj == undefined
            ? w.customer == undefined
              ? ""
              : w.customer.cnpj
            : this.profile.customer.cnpj,
      };

      this.setUserRegistration(w);
    }
    return w;
  }

  public getUserWCTrustedToken() {
    const WCTrustedToken = this.getCookie("WCTrustedToken");
    return WCTrustedToken;
  }

  public getUserWCToken() {
    const WCToken = this.getCookie("WCToken");
    return WCToken;
  }
}
