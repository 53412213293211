function load() {
  (function (a, s, y, n, c, h, i, d, e) {
    s.className += " " + y;
    h.start = 1 * new Date().getTime();
    h.end = i = function () {
      s.className = s.className.replace(RegExp(" ?" + y), "");
    };
    (a[n] = a[n] || []).hide = h;
    setTimeout(function () {
      i();
      h.end = null;
    }, c);
    h.timeout = c;
    // console.log("Anti-flicker actived!")
  })(window, document.documentElement, "async-hide", "dataLayer", 4000, {
    "GTM-MMRS6F9": true,
    start: 0,
    end: null,
    timeout: null,
  });
}

function call() {
  return `${load.name}()`;
}

export { load, call };
