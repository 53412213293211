import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NewHomeComponent } from "./new-home.component";
import { HeaderSpot } from "./header-spot/header-spot.component";
import { SpotComponent } from "./spot/spot.component";
import { DeferLoadModule } from "@trademe/ng-defer-load";
import { ModalInfoComponent } from "./header-spot/modal-info/modal-info.component";
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
} from "@angular/material";
import { EscapeHtmlPipe } from "./escape-html.pipe";
import { RouterModule } from "@angular/router";
import { RunScriptsDirective } from "./runScriptExternal/runScriptExternal.directive";

@NgModule({
  declarations: [
    NewHomeComponent,
    SpotComponent,
    HeaderSpot,
    ModalInfoComponent,
    EscapeHtmlPipe,
    RunScriptsDirective
  ],
  imports: [
    CommonModule,
    DeferLoadModule,
    MatIconModule,
    MatDialogModule,
    RouterModule,
    MatButtonModule,
  ],
  providers: [EscapeHtmlPipe],
  exports: [NewHomeComponent],
  entryComponents: [ModalInfoComponent],
})
export class NewHomeModule {}
