export namespace NClearSale {
    export enum EMarker {
        Home = 'home',
        Category = 'category',
        Search = 'search',
        Product = 'product',
        Cart = 'cart',
        CreateAccount = 'create-account',
        EditAccount = 'edit-account',
        PasswordReset = 'password-reset',
        LoginFail = 'login-fail',
        ContactUs = 'contact-us',
        Checkout = 'checkout',
        PurchaseConfirmation = 'purchase-confirmation',
    }

    export enum EPaymentType {
        CreditCard = 'credit-card',
        DebitCard = 'debit-card',
        Deposit = 'deposit',
        Transfer = 'transfer',
        Paypal = 'paypal',
        Other = 'other',
    }
}

/*
DONE home	Página inicial do website.
DONE category	Página com uma lista de produtos de determinada categoria.
DONE cart	Página do carrinho de compras.
DONE create-account	Página de criação de conta.
DONE login-fail	Página de erro no login do usuário.
DONE checkout	Página do processo de checkout.
DONE search	Página com resultados de uma busca.
  key-words	Palavras utilizadas em uma busca.
  page-number	Número de uma página de busca.
DONE product	Página de um produto.
  name	Nome de um produto.
  sku	SKU de um produto.
DONE password-reset	Página para recuperação de senha.

edit-account	Página de edição de conta.
contact-us	Página com formulário para atendimento.

*/
