import { Directive, HostListener, Renderer2 } from "@angular/core";
import { AsyncScriptService } from "../async-script.service";
import { load, call } from "./clear-sale.script";
import { ClearSaleMapperService } from "./clear-sale-service";

@Directive({ selector: "[clearSale]" })
export class ClearSaleScricptDirective {
  constructor(
    private asyncScriptService: AsyncScriptService,
    private renderer: Renderer2,
    private clearSaleService: ClearSaleMapperService
  ) {}

  @HostListener("window:load", ["$event"])
  onLoad(): void {
    this.asyncScriptService.addRawScript(
      `${load.toString()}; ${call()}`,
      this.renderer,
      () => this.clearSaleService.setCSDMLoaded()
    );
  }
}
