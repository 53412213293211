import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  selector: "[runScripts]", // tslint:disable-line
  providers: [],
})
export class RunScriptsDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  // TODO: MELHORAR ESSE COMPORTAMENTO
  ngOnInit(): void {
    setTimeout(() => {
      // waiting for DOM rendering data
      this.reinsertScripts();
    }, 1000);
  }

  reinsertScripts(): void {
    const scripts = <HTMLScriptElement[]>(
      this.elementRef.nativeElement.getElementsByTagName("script")
    );
    const scriptsInitialLength = scripts.length;

    for (let i = 0; i < scriptsInitialLength; i++) {
      const script = scripts[i];
      const scriptCopy = <HTMLScriptElement>document.createElement("script");
      scriptCopy.type = script.type ? script.type : "text/javascript";

      if (script.innerHTML) {
        scriptCopy.innerHTML = script.innerHTML;

        setTimeout(() => {
          scriptCopy.async = false;
          script.parentNode.replaceChild(scriptCopy, script);
        }, 1500);
      } else if (script.src) {
        scriptCopy.src = script.src;
        scriptCopy.async = false;
        script.parentNode.replaceChild(scriptCopy, script);
      }
    }
  }
}
