import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FooterComponent } from "./footer.component";
import { PartnersSocialComponent } from "./partners-social/partners-social.component";
import { LinksComponent } from "./section-links/links/links.component";
import { SectionLinksComponent } from "./section-links/section-links.component";
import { AppBannersComponent } from "./apps-banners/apps-banners.component";
import { SocialMediaComponent } from "./social-media/social-media.component";
import { MatDividerModule } from "@angular/material";

@NgModule({
  declarations: [
    PartnersSocialComponent,
    FooterComponent,
    SectionLinksComponent,
    LinksComponent,
    AppBannersComponent,
    SocialMediaComponent,
  ],
  imports: [CommonModule, MatDividerModule],
  exports: [FooterComponent],
})
export class FooterModule {}
