import { Component } from "@angular/core";

@Component({
  selector:'lc-new-home',
  templateUrl: "./new-home.component.html",
  styleUrls: ["./new-home.component.scss"],
})
export class NewHomeComponent {
  lazyLoad = {};

  spots = [
    "lc-home-body-first",
    "lc-home-body-second",
    "lc-home-body-third",
    "lc-home-body-fourth",
    "lc-home-body-fifth",
    "lc-home-body-sixth",
    "lc-home-body-seventh",
    // "lc-home-body-eighth",
    // "lc-home-body-nineth",
  ];
}
