import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NewLayoutComponent } from "./new-layout/new-layout.component";

const routes: Routes = [
  {
    path: "home",
    component: NewLayoutComponent,
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    outlet: "modal",
  },
  {
    path: "register",
    loadChildren: () =>
      import("./register/register.module").then((m) => m.RegisterModule),
    outlet: "modal",
  },
  {
    path: "recovery-password",
    loadChildren: () =>
      import("./recovery-password/recovery-password.module").then(
        (m) => m.RecoveryPasswordModule
      ),
    outlet: "modal",
  },
  {
    path: "find-couple",
    loadChildren: () =>
      import("./find-couple/find-couple.module").then(
        (m) => m.FindCoupleModule
      ),
  },
  {
    path: "c",
    loadChildren: () =>
      import("./couple/couple.module").then((m) => m.CoupleModule),
  },
  {
    path: ":uri-couple",
    loadChildren: () =>
      import("./page-couple/page-couple.module").then(
        (m) => m.PageCoupleModule
      ),
  },
  { path: "", redirectTo: "home", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
