import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { SpotHomeHeader } from "src/app/models/spot-home-header.model";
import { HomeSpotService } from "src/app/services/homespot.service";
import { ModalInfoComponent } from "./modal-info/modal-info.component";
import { Subscription, of, throwError, timer } from "rxjs";
import { catchError } from "rxjs/operators";

@Component({
  selector: "lc-header-spot",
  templateUrl: "./header-spot.component.html",
  styleUrls: ["./header-spot.component.scss"],
})
export class HeaderSpot implements OnInit {
  constructor(
    private spotService: HomeSpotService,
    private breakPoints: BreakpointObserver,
    private matDialog: MatDialog
  ) {
    this.verifyIsMobile();
  }

  containerStyle;
  firstButtonStyle;
  secondButtonStyle;
  firstButtonText: string = "";
  secondButtonText: string = "";
  firstText: string = "";
  secondText: string = "";
  firstButtonLink: string = "";
  secondButtonLink: string = "";
  videoUrl: string;
  isMobile: boolean;
  backgroundImageUrl: string;
  showImage: boolean;
  private subscriptions: Subscription[] = [];
  bkpStyle: SpotHomeHeader = {
    align_items: "center",
    buttons: {
      first_button: {
        label: "Criar lista grátis",
        background: "#e2645a",
        border_color: "invisible",
        link: "",
        text_color: "#FFFF",
      },
      second_button: {
        label: "Presentear noivos",
        link: "",
        text_color: "#FFFF",
      },
    },
    image: {
      desktop:
        "https://apigw-qa.fastshop.com.br/ecommerce/v0/ecommerces/wcsstore/FastShopCAS/images/lc/hero_1.png",
      mobile:
        "https://apigw-qa.fastshop.com.br/ecommerce/v0/ecommerces/wcsstore/FastShopCAS/images/lc/hero_1_mobile.png",
    },
    text: { first: "", second: "" },
    video: {
      desktop:
        "https://fastshopwr-a.akamaihd.net/videos/lejour/Le_Jour_HomeDesk01.mp4",
      mobile:
        "https://fastshopwr-a.akamaihd.net/videos/lejour/Le_Jour_HomeMobileApp01.mp4",
    },
  };

  ngOnInit(): void {
    this.getSpot();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getSpot() {
    const subscription = this.spotService
      .getSpot("lc-home-header")
      .pipe(
        catchError((error) => {
          this.changeStyle(this.bkpStyle);
          return of(error);
        })
      )
      .subscribe(
        (response) => {
          try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(response, "text/html");
            const script = doc.querySelectorAll("script");
            const filledScript = Array.from(script).find(
              (present) => !!(present.textContent || present.innerText)
            );
            if (!filledScript) throw "Script não encontrado.";
            const value = filledScript.textContent || filledScript.innerText;
            const regex = /var\s+content\s+=\s+({[\s\S]*?});/;
            const content = value.replace(/\n/g, "").match(regex);
            this.changeStyle(JSON.parse(content[1]));
          } catch (error) {
            console.error(error);
            this.changeStyle(this.bkpStyle);
          }
        },
        () => {
          console.error("Houve um erro de conexão com o spot da header");
          this.changeStyle(this.bkpStyle);
        }
      );

    this.subscriptions.push(subscription);
  }

  showInfo() {
    this.matDialog.open(ModalInfoComponent, {
      panelClass: "modal-info",
      minHeight: "600px",
      width: "850px",
    });
  }

  verifyIsMobile() {
    this.breakPoints
      .observe("(max-width:476px)")
      .subscribe((result) => (this.isMobile = result.matches));
    console.log("IsMobile:", this.isMobile);
  }

  changeStyle(spotData: SpotHomeHeader) {
    if (spotData.text) {
      this.firstText = spotData.text.first;
      this.secondText = spotData.text.second;
    }

    if (spotData.buttons) {
      if (spotData.buttons.first_button) {
        this.firstButtonText = spotData.buttons.first_button.label;
        this.firstButtonLink = spotData.buttons.first_button.link;
        this.firstButtonStyle = {
          backgroundColor: spotData.buttons.first_button.background,
          borderColor: spotData.buttons.first_button.border_color,
          color: spotData.buttons.first_button.text_color,
        };
      }
      if (spotData.buttons.second_button) {
        this.secondButtonText = spotData.buttons.second_button.label;
        this.secondButtonStyle = {
          color: spotData.buttons.second_button.text_color,
        };
        this.secondButtonLink = spotData.buttons.second_button.link;
      }
    }

    if (spotData.image) {
      this.containerStyle = {
        alignItems: spotData.align_items,
      };

      setTimeout(() => {
        this.showImage = true;
        this.backgroundImageUrl = this.isMobile
          ? spotData.image.mobile
          : spotData.image.desktop;
      }, 3000);
    }

    if (spotData.video) {
      this.videoUrl = this.isMobile
        ? spotData.video.mobile
        : spotData.video.desktop;
    }

    timer(10).subscribe(() => {
      // setTimeout(() => {
      if (this.videoUrl) {
        const video = document.getElementById("video") as HTMLVideoElement;
        video.play();
        video.style.opacity = "1";
      }
    });
  }
}
