function load() {
  (function (a, b, c, d, e, f, g) {
    a["CsdmObject"] = e;
    (a[e] =
      a[e] ||
      function () {
        (a[e].q = a[e].q || []).push(arguments);
      }),
      (a[e].l = 1 * new Date().getTime());
    (f = b.createElement(c)), (g = b.getElementsByTagName(c)[0]);
    f.defer = 1;
    f.src = d;
    g.parentNode.insertBefore(f, g);
  })(window, document, "script", "//device.clearsale.com.br/m/cs.js", "csdm");
  window['csdm']("app", "8bdc1c8f42");

  (function (a, b, c, d, e, f, g) {
    a["CsdpObject"] = e;
    (a[e] =
      a[e] ||
      function () {
        (a[e].q = a[e].q || []).push(arguments);
      }),
      (a[e].l = 1 * new Date().getTime());
    (f = b.createElement(c)), (g = b.getElementsByTagName(c)[0]);
    f.defer = 1;
    f.src = d;
    g.parentNode.insertBefore(f, g);
    // console.log("Clear-sale actived!")
  })(window, document, "script", "//device.clearsale.com.br/p/fp.js", "csdp");
  window['csdp']("app", "8bdc1c8f42");
  window['csdp']("outputsessionid", "lc_fraud_id");
}

function call() {
  return `${load.name}()`;
}

export { load, call };
