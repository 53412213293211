import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "lc-notice",
  templateUrl: "./notice.component.html",
  styleUrls: ["./notice.component.scss"],
})
export class NoticeComponent implements OnInit {
  constructor(private breakPoints: BreakpointObserver) {}

  isMobile: boolean;
  @Input() noticeStyle;

  ngOnInit(): void {
    this.breakPoints
      .observe("(max-width: 476px)")
      .subscribe((result) => (this.isMobile = result.matches));
  }
}
