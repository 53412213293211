import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, fromEvent } from "rxjs";

@Component({
  selector: "lc-layout",
  templateUrl: "./new-layout.component.html",
  styleUrls: ["./new-layout.component.scss"],
})
export class NewLayoutComponent implements OnInit, OnDestroy {
  constructor(private breakPoints: BreakpointObserver) {}

  isMobile: boolean = false;
  showNotice = true;
  showHeader = true;
  subscriptions = new Subscription();
  headerStyle;
  noticeStyle;
  lazyLoad = {};
  footerLoad = false;

  ngOnInit(): void {
    this.observeIsMobile();
    this.observeScroll();

    setTimeout(() => {
      this.footerLoad = true;
    }, 10000);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  observeIsMobile() {
    const subscription = this.breakPoints
      .observe("(max-width:476px)")
      .subscribe((result) => (this.isMobile = result.matches));
    this.subscriptions.add(subscription);
  }

  observeScroll() {
    const subcription = fromEvent(window, "scroll").subscribe((event: any) => {
      this.showNotice = window.scrollY === 0;
      if (!this.showNotice && !this.isMobile) {
        this.headerStyle = { backgroundColor: "rgba(0, 0, 0, 0.315)" };
        this.noticeStyle = { display: "none" };
      } else {
        this.headerStyle = { backgroundColor: "transparent" };
        this.noticeStyle = { display: "flex" };
      }
    });
    this.subscriptions.add(subcription);
  }
}
